import './App.css';
import ResponsiveAppBar from './components/AppBar';
import { Routes, Route } from "react-router-dom";
import NewPaste from './pages/NewPaste';
import ViewPaste from './pages/ViewPaste';

function App() {
  return (
    <div className="App">
      <ResponsiveAppBar />
      <Routes>
        <Route path="/" element={<NewPaste />} />
        <Route path=":url" element={<ViewPaste />} />
      </Routes>
    </div>
  );
}

export default App;
