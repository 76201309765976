import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

function ViewPaste() {
    const params = useParams()
    const [paste, setPaste] = useState("Loading.....");
    const endPoint = 'https://pastebin-clone-2.herokuapp.com';

    useEffect(() => {
        axios.get(`${endPoint}/urls/${params.url}`)
            .then((response) => {
                setPaste(response.data.data);
            })
            .catch(function (error) {
                console.error(error);
                setPaste(`No data was found with the URL ${params.url}`);
            });
    }, [params])

    return (
        <div style={{ padding: '20px' }}>
            <TextField
                id="outlined-multiline-static"
                label="The paste"
                multiline
                fullWidth
                rows={30}
                value={paste}
            />
        </div>
    );
}

export default ViewPaste;
