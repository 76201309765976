import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

function NewPaste() {
    const [paste, setPaste] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const endPoint = 'https://pastebin-clone-2.herokuapp.com';
    const postNewPaste = () => {
        setLoading(true);
        const endPointUrl = `${endPoint}/urls`;
        const payload = {
            data: paste,
        }
        console.log("post to", endPointUrl);
        console.log("payload", payload);
        axios.post(endPointUrl, payload)
            .then(function (response) {
                navigate(`/${response.data.tinyUrl}`);
            })
            .catch(function (error) {
                console.error(error);
                setPaste('');
            });
    }
    return (
        <div style={{ padding: '20px' }}>
            <TextField
                id="outlined-multiline-static"
                label="New Paste"
                multiline
                fullWidth
                rows={30}
                onChange={(e) => {
                    setPaste(e.target.value)
                }}
                value={paste}
            />
            <div style={{ marginTop: '10px' }}>

                {loading ?
                    <LoadingButton loading variant="outlined">
                        Submit
                    </LoadingButton> : <Button variant="contained" disabled={paste.length === 0} onClick={postNewPaste}>Create New Paste</Button>}
            </div>

        </div>
    );
}

export default NewPaste;
